import {V2_MetaFunction} from '@shopify/remix-oxygen';

export const metaFunction: V2_MetaFunction = ({data, matches}) => {
  const language: 'ro' | 'en' = (matches?.[0] as any)?.params?.locale ?? 'en';
  const {pageData} = data;

  const currentRoute = (matches[1] as any)?.pathname?.replace(
    `/${language}`,
    '',
  );

  const metaObject = pageData?.metaobjects?.edges?.find(
    (edge: any) =>
      edge?.node?.fields?.find((field: any) => field?.key === 'page_handle')
        ?.value === currentRoute,
  )?.node;

  const title = metaObject?.fields?.find((field: any) =>
    language === 'en'
      ? field?.key === 'en_meta_title'
      : field?.key === 'ro_meta_title',
  )?.value;

  const description = metaObject?.fields?.find((field: any) =>
    language === 'en'
      ? field?.key === 'en_meta_description'
      : field?.key === 'ro_meta_description',
  )?.value;

  const image = metaObject?.fields?.find(
    (field: any) => field?.key === 'meta_image',
  )?.reference?.image?.url;

  return [
    title && {title},
    description && {name: 'description', content: description},
    description && {name: 'og: description', content: description},
    image && {name: 'image', content: image},
    image && {name: 'og:image', content: image},
  ].filter(Boolean);
};
